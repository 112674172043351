import * as React from "react"
import { StoreContext } from "../context/store-context"

export function AddToCart({ variantId, quantity, IsSelected, hasVariants, ...props }) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()

    addVariantToCart(variantId, quantity, props.customAttributes)
  }

  return (
    <div>
      {props.isSeatBeltColor || props.isSeatBeltWebbing || props.isSeatCoverStitching ?
        <div>
          <button
            type="submit"
            className="font-semibold m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred disabled:bg-transparent text-white disabled:text-lndred hover:shadow-lg disabled:shadow-none hover:shadow-lndred transition duration-150 ease-in-out text-xs md:text-base"
            onClick={addToCart}
            disabled={(!IsSelected && hasVariants) || loading}
            {...props}
            title="Place Your Repair Order"
            alt="Place Your Repair Order - L&D Solutions"
          >
            {IsSelected ? "Place Your Repair Order" : "Select Option"}
          </button>
        </div>
        : props.disablePlugs && props.title.includes('Airbag Plugs') ?
          <div>
            <button
              type="submit"
              className="font-semibold m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred disabled:bg-transparent text-white disabled:text-lndred hover:shadow-lg disabled:shadow-none hover:shadow-lndred transition duration-150 ease-in-out text-xs md:text-base"
              onClick={addToCart}
              disabled={props.disablePlugs}
              {...props}
              title="Purchase Airbag Plugs"
              alt="Purchase Airbag Plugs - L&D Solutions"
            >
              {!props.disablePlugs ? "Purchase Airbag Plugs" : "Select Option"}
            </button>
          </div>
          :
          <div>
            <button
              type="submit"
              className="font-semibold m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred disabled:bg-transparent text-white disabled:text-lndred hover:shadow-lg disabled:shadow-none hover:shadow-lndred transition duration-150 ease-in-out text-xs md:text-base"
              onClick={addToCart}
              disabled={loading}
              {...props}
              title="Place Your Repair Order"
              alt="Place Your Repair Order - L&D Solutions"
            >
              Place Your Repair Order
            </button>
          </div>
      }
    </div>
  )
}
