import React from "react"
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { useLocation } from '@reach/router'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ArrowUp from "../icons/arrow-up"

const YouMayLike = (props) => {

    const servicesList = [
        { id: '1', serviceName: 'Single Stage Seat Belt Repair', price: '$69.99', slug: '/services/seat-belt-repair-service/single-stage-seat-belts', image: <GatsbyImage image={props.singleStageData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.singleStageData[0].node.image.title} alt={props.singleStageData[0].node.image.description} /> },
        { id: '2', serviceName: 'Dual Stage Seat Belt Repair', price: '$94.99', slug: '/services/seat-belt-repair-service/dual-stage-seat-belt', image: <GatsbyImage image={props.dualStageData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.dualStageData[0].node.image.title} alt={props.dualStageData[0].node.image.description} /> },
        { id: '3', serviceName: 'Triple Stage Seat Belt Repair', price: '$109.99', slug: '/services/seat-belt-repair-service/triple-stage-seat-belt', image: <GatsbyImage image={props.tripleStageData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.tripleStageData[0].node.image.title} alt={props.tripleStageData[0].node.image.description} /> },
        { id: '4', serviceName: 'Pretensioner Repair', price: '$69.99', slug: '/services/seat-belt-repair-service/pretensioner', image: <GatsbyImage image={props.pretensionerData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.pretensionerData[0].node.image.title} alt={props.pretensionerData[0].node.image.description} /> },
        { id: '5', serviceName: 'Seat Belt Coloring', price: '$79.99', slug: '/services/seat-belt-coloring-service/seat-belt-coloring/', image: <GatsbyImage image={props.seatBeltColorData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.seatBeltColorData[0].node.image.title} alt={props.seatBeltColorData[0].node.image.description} /> },
        { id: '6', serviceName: 'Deployed Seat Repair', price: '$120 - $150', slug: '/services/deployed-seat-repair-service/deployed-seat-repair/', image: <GatsbyImage image={props.seatCoverStitchingData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.seatCoverStitchingData[0].node.image.title} alt={props.seatCoverStitchingData[0].node.image.description} /> },
        { id: '7', serviceName: 'Battery Cable Repair', price: '$50.00', slug: '/services/battery-cable-repair-service/battery-cable', image: <GatsbyImage image={props.batteryCableData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.batteryCableData[0].node.image.title} alt={props.batteryCableData[0].node.image.description} /> },
        { id: '8', serviceName: 'Steering Column Repair', price: '$49.99', slug: '/services/steering-column-sensor-repair-service/collapsible-steering-sensor', image: <GatsbyImage image={props.steeringColumnData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.steeringColumnData[0].node.image.title} alt={props.steeringColumnData[0].node.image.description} /> },
        { id: '9', serviceName: 'SRS Airbag Module Repair', price: '$54.99 - $179.99', slug: '/services/srs-airbag-module-repair-service/airbag-module-reset', image: <GatsbyImage image={props.srsAirBagData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.srsAirBagData[0].node.image.title} alt={props.srsAirBagData[0].node.image.description} /> },
        { id: '10', serviceName: 'Convertible Roll Bar Repair', price: '$149.99', slug: '/services/convertible-roll-bar-repair-service/convertible-roll-bar', image: <GatsbyImage image={props.convertibleRollBarData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.convertibleRollBarData[0].node.image.title} alt={props.convertibleRollBarData[0].node.image.description} /> },
        { id: '11', serviceName: 'Seat Belt Webbing', price: '$79.99', slug: '/services/seat-belt-webbing-replacement-service/seat-belt-webbing-replacement', image: <GatsbyImage image={props.seatBeltWebbingData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.seatBeltWebbingData[0].node.image.title} alt={props.seatBeltWebbingData[0].node.image.description} /> },
        { id: '12', serviceName: 'Seat Reupholstering', price: 'Request A Quote', slug: '/services/seat-reupholstering-service/seat-reupholstering', image: <GatsbyImage image={props.fullSeatReupholsteringData[0].node.image.gatsbyImageData} placeholder="blurred" className="rounded-t-lg h-60 z-0 bg-white" title={props.fullSeatReupholsteringData[0].node.image.title} alt={props.fullSeatReupholsteringData[0].node.image.description} /> },
    ];

    function getRandomServices() {
        const indices = [];
        while (indices.length < 3) {
            const randomIndex = Math.floor(Math.random() * servicesList.length);
            if (!indices.includes(randomIndex)) {
                indices.push(randomIndex);
            }
        }
        return indices.map(index => servicesList[index]);
    }
    const randomServices = getRandomServices();

    const page = useLocation()

    var contentfulVariable = ''

    if (page.pathname.includes("single-stage-seat-belts")) {
        contentfulVariable = props.singleStageData
    }
    else if (page.pathname.includes("dual-stage-seat-belt")) {
        contentfulVariable = props.dualStageData
    }
    else if (page.pathname.includes("triple-stage-seat-belt")) {
        contentfulVariable = props.tripleStageData
    }
    else if (page.pathname.includes("pretensioner")) {
        contentfulVariable = props.pretensionerData
    }
    else if (page.pathname.includes("seat-belt-coloring")) {
        contentfulVariable = props.seatBeltColorData
    }
    else if (page.pathname.includes("seat-belt-webbing")) {
        contentfulVariable = props.seatBeltWebbingData
    }
    else if (page.pathname.includes("deployed-seat-repair")) {
        contentfulVariable = props.seatCoverStitchingData
    }
    else if (page.pathname.includes("seat-reupholstering")) {
        contentfulVariable = props.fullSeatReupholsteringData
    }
    else if (page.pathname.includes("battery-cable")) {
        contentfulVariable = props.batteryCableData
    }
    else if (page.pathname.includes("airbag-plugs")) {
        contentfulVariable = props.airbagPlugsData
    }
    else if (page.pathname.includes("collapsible-steering-sensor")) {
        contentfulVariable = props.steeringColumnData
    }
    else if (page.pathname.includes("airbag-module-reset")) {
        contentfulVariable = props.srsAirBagData
    }
    else if (page.pathname.includes("convertible-roll-bar")) {
        contentfulVariable = props.convertibleRollBarData
    }
    else {
        contentfulVariable = ''
    }

    return (
        <div>
            <div className="bg-newlndgray">
                <div className="container mx-auto pt-12 md:pt-20 md:pb-10">
                    <div className="relative hover:opacity-80 pt-3">
                        <div className="absolute right-[130px] md:right-[25px] xl:right-[30px] 2xl:right-[150px] hidden lg:flex">
                            <Link to='/services' className="text-lndred">View All</Link>
                            <svg className="pt-1" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#FF5A30" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <h2 className="px-12 md:px-5 font-bold text-2xl md:text-2xl lg:text-3xl text-left xl:px-[25px] 2xl:px-[145px]">Other Services</h2>
                    <div className="px-5 pt-5 pb-10 2xl:px-32 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9 md:gap-5">

                        {randomServices.map((service) => (
                            <div key={service.id} className="max-w-sm mx-auto border border-gray-400 shadow-xl rounded-lg bg-white hover:border-lndred transition duration-150 ease-in-out">
                                <Link to={service.slug}>
                                    {service.image}
                                </Link>
                                <hr className="border-2" />
                                <div className="px-2">
                                    <div className="h-20 px-4">
                                        <h3 className="text-xl font-semibold pt-5">{service.serviceName}</h3>
                                        <p className="text-xl font-bold pt-2">{service.price}</p>
                                    </div>
                                    <div className="pt-3 lg:pt-8 2xl:pt-4 text-center">
                                        <Link to={service.slug} title="Explore Seat Belt Repair Service" alt="Explore Seat Belt Repair Service">
                                            <button className='m-5 relative h-10 py-2 px-24 mx-auto rounded-lg border border-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out text-lndred hover:bg-lndred hover:text-white'>
                                                <span>View Details</span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                    <div className="mx-auto flex justify-center pb-10 lg:hidden">
                        <Link to='/services' className="text-lndred">View All</Link>
                        <svg className="pt-1" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#FF5A30" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div >

            {page.pathname.includes('/services/') ?
                <div className="bg-lndred text-white text-center text-3xl font-bold pt-10 pb-5 lg:flex mx-auto justify-center md:gap-x-10">
                    <span className="pt-5">{contentfulVariable[0].node.footerText}</span>
                    <div>
                        <AnchorLink to={page.pathname + '#services'} title='Back To Ordering' alt='Back To Ordering'>
                            <button className='m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-white bg-white text-lndred hover:opacity-80 focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                <span className="flex gap-x-1 text-sm"><ArrowUp />Back To Ordering</span>
                            </button>
                        </AnchorLink>
                    </div>
                </div>
                : ''
            }
        </div>
    )
}
export default YouMayLike