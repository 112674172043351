import * as React from "react"

function ArrowUp(props) {
    return (
        <svg className='mt-1' width="10" height="10" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 17V1M7.5 1L1.5 7M7.5 1L13.5 7" stroke="#FD592F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default ArrowUp
